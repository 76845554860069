import React from 'react';

import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

export default function SSRPage({ noindexMeta = true }) {
  return (
    <Helmet
      title={'VABYSMO.co.nz'}
      meta={[
        ...(noindexMeta ? [{ name: 'robots', content: 'noindex,follow' }] : []),
        {
          name: 'description',
          content:
            'You have been prescribed VABYSMO. Look at this website to learn more about your medicine and other useful information.'
        }
      ]}
    />
  );
}

SSRPage.propTypes = {
  noindexMeta: PropTypes.bool
};
